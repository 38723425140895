<template>
  <view-item title="服务历史">
    <template #manifest>
      <table-manifest
        
        :ajax="{ action: 'GET /enocloud/business/opportunity/record/query' }"
        :props="{ start: 'preparedDateStart', end: 'preparedDateEnd' }"
        @expand-click="dialog.visible = true"
        @row-click="manifest.row.click"
        :addition="{ branchIds: store.user.branch?.id ? [store.user.branch?.id] : [] }"
      >
      <en-table-column label="服务编号" prop="serialNo" ></en-table-column>
        <en-table-column label="客户名称" prop="customerName" ></en-table-column>
        <en-table-column label="服务类型" prop="categoryKey.message" ></en-table-column>
        <template #form="{ data }">
          <en-form-item label="单据搜索" placeholder="车牌号/客户名称/联系人手机">
        <en-input v-model="data.quickSearch"></en-input>
      </en-form-item>
      <en-form-item label="服务类型">
        <select-maintain
          v-model="data.categoryKey"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['BNSOPRTCTGY']) }"
          :props="{ label: 'message', value: 'code' }"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="服务阶段">
        <select-maintain
          v-model="data.type"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['BORCTP']) }"
          :props="{ label: 'message', value: 'code' }"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="跟进时间">
        <en-date-picker
          v-model:start="data.preparedDateStart"
          v-model:end="data.preparedDateEnd"
          type="daterange"
          class="w-full"
        ></en-date-picker>
      </en-form-item>
      <en-form-item label="跟进人员">
        <select-maintain
          v-model="data.preparedById"
          :ajax="{
            action: 'GET /enocloud/common/user',
            params: (params, name) => (params.payload = { name, includingDestroyed: true, advisorFirst: true })
          }"
          :props="{ label: 'name', value: 'id' }"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="服务备注">
        <en-input v-model="data.businessOpportunityComment" type="textarea"></en-input>
      </en-form-item>
      <en-form-item label="跟进内容">
        <en-input v-model="data.comment" type="textarea"></en-input>
      </en-form-item>
     
      
  
      <en-form-item label="选择门店">
        <select-maintain
          v-model="data.branchIds"
          :ajax="{
            action: 'GET /enocloud/common/branch',
            params: (params) => {
              params.payload = { pagingEnabled: false }
            }
          }"
          :props="{ label: 'shortName', value: 'id' }"
          clearable
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="最后进厂日期">
        <en-date-picker
          v-model:start="data.lastServiceDateStart"
          v-model:end="data.lastServiceDateEnd"
          type="daterange"
          class="w-full"
        ></en-date-picker>
      </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!opportunity.data.id">
      <en-card v-loading="opportunity.loading" body-class="flex gap-6 text-sm">
        <span>{{ opportunity.data.vehicle?.plateNo }}</span>
        <span>{{ opportunity.data.customer?.name }}/{{ opportunity.data.customer?.cellphone }}</span>
        <span>{{ opportunity.data.vehicle?.vehicleSpec.join('/') }}</span>
        <span>服务类型：{{ opportunity.data.categoryKey?.message }}</span>
        <span>到期时间：{{ formatDate(opportunity.data.dueDate) }}</span>
        <span>服务备注：{{ opportunity.data.comment }}</span>
      </en-card>

      <en-card v-loading="opportunity.loading" class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <template #default="{ height }">
            <en-tabs v-model="tabs.active">
              <en-tab-pane label="跟进记录" name="record">
                <en-table :data="record.data" :loading="record.loading" :height="height - 55" pagination :paging="record.paging" :method="record.get">
                  <en-table-column label="跟进内容" prop="comment"></en-table-column>
                  <en-table-column label="服务阶段" prop="type.message"></en-table-column>
                  <en-table-column label="跟进日期" prop="preparedDatetime">
                    <template #default="{ row }: { row: EnocloudBusinessOpportunityDefinitions['BusinessOpportunityRecordsDto'] }">
                      {{ formatDate(row.preparedDatetime) }}
                    </template>
                  </en-table-column>
                  <en-table-column label="下次跟进日期" prop="nextFollowupDate">
                    <template #default="{ row }: { row: EnocloudBusinessOpportunityDefinitions['BusinessOpportunityRecordsDto'] }">
                      {{ formatDate(row.nextFollowupDate) }}
                    </template>
                  </en-table-column>
                </en-table>
              </en-tab-pane>
            </en-tabs>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>

  <opportunity-record-dialog v-model="dialog.visible"></opportunity-record-dialog>
</template>

<script lang="ts">
import OpportunityRecordDialog from '@client/components/opportunity-record-dialog.vue'
import { useStore } from '@enocloud/utils'
const store = useStore()
export default factory({
  components: { OpportunityRecordDialog },

  config: {
    children: {
      manifest: {
        row: {
          async click(row: EnocloudBusinessOpportunityDefinitions['BusinessOpportunityRecordQueryDto']) {
            this.opportunity.data.id = row.businessOpportunityId
            await this.opportunity.get()
            this.record.get()
          }
        }
      },
      tabs: {
        active: 'record'
      },
      opportunity: {
        ajax: {
          get: {
            action: 'GET /enocloud/business/opportunity/:opportunityId',
            data: 'object',
            loading: true,
            params(params) {
              params.paths = [this.opportunity.data.id]
            }
          },
          discard: {
            action: 'PUT /enocloud/business/opportunity/:opportunityId/discard',
            loading: true,
            params(params) {
              params.paths = [this.opportunity.data.id]
            }
          }
        }
      },
      record: {
        ajax: {
          get: {
            action: 'GET /enocloud/business/opportunity/record/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { businessOpportunityId: this.opportunity.data.id }
            }
          }
        }
      },
      dialog: {
        visible: false
      }
    }
  }
})
</script>
